<template>
  <div class='container'>

    <!-- Test FS -->
    <div class='test-fs' v-if='showFS' v-bind:class='{ loading : isLoading }'>
      <div class='leftside' />
      <div class='middle' v-bind:class='{ wrong : showIncorrectAnimation }'>
        <div class='questions'>
          <div><img :src='resolve(item.q1)'/></div>
          <div><img :src='resolve(item.q2)'/></div>
          <div><img :src='resolve(item.q3)'/></div>
          <div><img :src='resolve(item.q4)'/></div>
          <div class='result'><img src='../../assets/img/result.png' /></div>
        </div>
        <img src="../../assets/img/line.png"/>
        <div class='answers'>
          <div class='a1' v-bind:class='{ highlight : isAnswer1 }'><img :src='resolve(item.a1)' v-on:click='answer(1)'/></div>
          <div class='a2' v-bind:class='{ highlight : isAnswer2 }'><img :src='resolve(item.a2)' v-on:click='answer(2)'/></div>
          <div class='a3' v-bind:class='{ highlight : isAnswer3 }'><img :src='resolve(item.a3)' v-on:click='answer(3)'/></div>
          <div class='a4' v-bind:class='{ highlight : isAnswer4 }'><img :src='resolve(item.a4)' v-on:click='answer(4)'/></div>
        </div>
      </div>
      <div class='rightside'>
        <div class='next' v-on:click='next()'></div>
      </div>
    </div>

    <!-- Test FA -->
    <div class='test-fa' v-if='showFA' v-bind:class='{ loading : isLoading }'>
      <div class='leftside' />
      <div class='middle' v-bind:class='{ wrong : showIncorrectAnimation }'>
        <div class='questions'>
          <div><img :src='resolve(item.q1)'/></div>
          <div><img :src='resolve(item.q2)'/></div>
          <div><img :src='resolve(item.q3)'/></div>
          <div class='result'><img src='../../assets/img/result.png' /></div>
        </div>
        <img src="../../assets/img/line.png"/>
        <div class='answers'>
          <div class='a1' v-bind:class='{ highlight : isAnswer1 }'><img :src='resolve(item.a1)' v-on:click='answer(1)'/></div>
          <div class='a2' v-bind:class='{ highlight : isAnswer2 }'><img :src='resolve(item.a2)' v-on:click='answer(2)'/></div>
          <div class='a3' v-bind:class='{ highlight : isAnswer3 }'><img :src='resolve(item.a3)' v-on:click='answer(3)'/></div>
          <div class='a4' v-bind:class='{ highlight : isAnswer4 }'><img :src='resolve(item.a4)' v-on:click='answer(4)'/></div>
        </div>
      </div>
      <div class='rightside'>
        <div class='next' v-on:click='next()'></div>
      </div>
    </div>

    <div class='test-pc' v-if='showPC' v-bind:class='{ loading : isLoading }'>
      <div class='leftside' />
      <div class='middle' v-bind:class='{ wrong : showIncorrectAnimation }'>
        <div class='questions'>
          <div><img :src='resolve(item.q1)'/></div>
          <div><img :src='resolve(item.q2)'/></div>
          <div><img :src='resolve(item.q3)'/></div>
          <div><img :src='resolve(item.q4)'/></div>
          <div><img :src='resolve(item.q5)'/></div>
          <div><img :src='resolve(item.q6)'/></div>
          <div><img :src='resolve(item.q7)'/></div>
          <div><img :src='resolve(item.q8)'/></div>
          <div class='result'><img src='../../assets/img/result.png'/></div>
        </div>
        <img src="../../assets/img/line.png"/>
        <div class='answers'>
          <div class='a1' v-bind:class='{ highlight : isAnswer1 }'><img :src='resolve(item.a1)' v-on:click='answer(1)'/></div>
          <div class='a2' v-bind:class='{ highlight : isAnswer2 }'><img :src='resolve(item.a2)' v-on:click='answer(2)'/></div>
          <div class='a3' v-bind:class='{ highlight : isAnswer3 }'><img :src='resolve(item.a3)' v-on:click='answer(3)'/></div>
          <div class='a4' v-bind:class='{ highlight : isAnswer4 }'><img :src='resolve(item.a4)' v-on:click='answer(4)'/></div>
          <div class='a5' v-bind:class='{ highlight : isAnswer5 }'><img :src='resolve(item.a5)' v-on:click='answer(5)'/></div>
        </div>
      </div>
      <div class='rightside'>
        <div class='next' v-on:click='next()'></div>
      </div>
    </div>

  </div>
</template>

<script>

import firebase from 'firebase/app';
import moment from 'moment/src/moment'

const countDownMax = 180

export default {
  name: 'LPCATTest',
  props: {
    phase: Number,
    testCompleted: Function,
    testData: Object
  },
  mounted () {
    this.nextItem(this.phase, this.testData.answers).then(item => {
      this.showItem(item);
      this.initializeTimer();
      this.isLoading = false;
    })
  },
  beforeDestroy () {
    clearInterval(this.intervalTimer)
  },
  methods: {
    next () {
      if (this.selectedAnswer === false) {
        if (!this.showIncorrectAnimation) {
          this.showIncorrectAnimation = true
          setTimeout(() => {
            this.showIncorrectAnimation = false
          }, 500)
        }
        return
      }

      var end = moment().unix()
      var duration = (end - this.questionTimer)
      var answer = this.selectedAnswer
      var changes = this.answerChanges

      this.testData.answers.push({ answer, changes, duration })
      this.selectedAnswer = false

      this.nextItem(this.phase, this.testData.answers).then(item => {
        if (item) {
          this.showItem(item);
          this.countDown = countDownMax;
        } else {
          this.countDown = 0;
          this.testCompleted(this.testData);
        }
        this.isLoading = false;
      })
    },
    answer (selectedAnswer) {
      this.selectedAnswer = selectedAnswer
      this.isAnswer1 = selectedAnswer === 1
      this.isAnswer2 = selectedAnswer === 2
      this.isAnswer3 = selectedAnswer === 3
      this.isAnswer4 = selectedAnswer === 4
      this.isAnswer5 = selectedAnswer === 5
      this.answerChanges++;
    },
    showItem (item) {
      if (!item) {
        alert('No item found for the current parameters')
        return
      }

      this.showIncorrectAnimation = false
      this.questionTimer = moment().unix()
      this.answerChanges = 0

      this.isAnswer1 = false
      this.isAnswer2 = false
      this.isAnswer3 = false
      this.isAnswer4 = false
      this.isAnswer5 = false
      this.showFA = item.item.startsWith('FA')
      this.showFS = item.item.startsWith('FS')
      this.showPC = item.item.startsWith('PC')
      this.item = item
    },
    nextItem (phase, answers) {
      this.isLoading = true;
      const loadItem = firebase.functions().httpsCallable('item');
      return loadItem({
        phase,
        answers
      }).then(result => {
        return result.data;
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    initializeTimer () {
      this.intervalTimer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--
          this.showIncorrectAnimation =
            this.countDown == 5 ||
            this.countDown == 3 ||
            this.countDown == 1
        } else {
          this.countDown = countDownMax
          this.selectedAnswer = 0
          this.next()
        }
      }, 1000)
    },
    resolve (item) {
      if (!item) return '';
      return item;
    },
  },
  data () {
    return {
      selectedAnswer: false,
      showFS: false,
      showFA: false,
      showPC: false,
      isAnswer1: false,
      isAnswer2: false,
      isAnswer3: false,
      isAnswer4: false,
      isAnswer5: false,
      questionTimer: 0,
      answerChanges: 0,
      item: false,
      showIncorrectAnimation: false,
      countDown: countDownMax,
      intervalTimer: false,
      isLoading: false
    }
  }
}
</script>

<style scoped>

  .lpcat {
    height: 100%;
    background: #303030;
    background: url(../../assets/img/background.jpg) center repeat scroll;
    color: #c3c3c3;
    font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-weight: 400;
  }
  h1 {
    color: #fff;
    font-size: 60px;
    line-height: 72px;
    font-weight: 300;
    margin-bottom: 36px;
  }
  h2 {
    color: #61ae24;
    font-size: 36px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 18px;
  }
  h3 {
    color: #61ae24;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  h4 {
    color: #61ae24;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  h5 {
    color: #c3c3c3;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  h6 {
    color: #c3c3c3;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 12px;
  }
  a {
    color: #61ae24;
    text-decoration: none;
    -webkit-transition: color 0.15s ease-in-out;
    -moz-transition: color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out;
    -webkit-transform: translateZ(0);
  }
  a:hover {
    color: #6dc129;
  }
  .color {
    color: #61ae24;
  }
  .blue {
    color: #00a1cb;
  }
  .green {
    color: #61ae24;
  }
  .orange {
    color: #f18d05;
  }
  .red {
    color: #e54028;
  }
  .hide {
    display: none;
  }
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    color: #fff;
    font-size: 18px;
    text-shadow: 0 1px 2px #222;
    background-color: #616161;
    margin: 0;
    padding: 0 10px;
    width: 193px;
    height: 36px;
    border: 1px solid rgba(0,0,0, 0.3);
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
    -webkit-transform: translateZ(0);
    cursor: pointer;
  }
  button.active,
  button:hover {
    background-color: #61ae24;
  }
  label {
    color: #c3c3c3;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  input,
  select,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font: 18px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    font-weight: 300;
    color: #61ae24;
    margin: 0;
    padding: 3px;
    background-color: #424242;
    border: 1px solid #424242;
    border-top-color: #353535;
    border-right-color: #404040;
    border-bottom-color: #5c5c5c;
    border-left-color: #404040;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .line {
    background: url(../../assets/img/line.png) center center no-repeat;
    height: 4px;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  /* Layout */

  .container {
    width: 956px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -478px;
    margin-top: -301px;
  }

  .container.show {
    display: block;
  }
  .page {
    background-color: #3e3e3e;
    min-height: 436px;
    padding: 52px 62px 114px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    position: relative;
  }
  .page.nobuttons {
    min-height: 494px;
    padding-bottom: 56px;
  }
  .quit,
  .prev,
  .next,
  .thumbs-up,
  .thumbs-down,
  .settings,
  .help {
    border: 1px solid rgba(0,0,0, 0.3);
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 31px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 2px 2px rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
    -webkit-transform: translateZ(0);
    cursor: pointer;
  }
  .quit {
    background: url(../../assets/img/quit.png) center center no-repeat #616161;
    background-image: url(../../assets/img/quit.svg), none;
    left: 31px;
  }
  .quit:hover {
    background-color: #545454;
  }
  .prev {
    background: url(../../assets/img/prev.png) center center no-repeat #616161;
    background-image: url(../../assets/img/prev.svg), none;
    left: 31px;
  }
  .prev:hover {
    background-color: #545454;
  }
  .prev.orange {
    background: url(../../assets/img/prev.png) center center no-repeat #f18d05;
    background-image: url(../../assets/img/prev.svg), none;
    left: 31px;
  }
  .prev.orange:hover {
    background-color: #f7981b;
  }
  .next {
    background: url(../../assets/img/next.png) center center no-repeat #61ae24;
    background-image: url(../../assets/img/next.svg), none;
    right: 31px;
  }
  .next:hover {
    background-color: #6dc129;
  }
  .thumbs-up {
    background: url(../../assets/img/thumbs-up.png) center center no-repeat #616161;
    background-image: url(../../assets/img/thumbs-up.svg), none;
  }
  .thumbs-down {
    background: url(../../assets/img/thumbs-down.png) center center no-repeat #616161;
    background-image: url(../../assets/img/thumbs-down.svg), none;
  }
  .thumbs-up.selected {
    background-color: #61ae24;
  }
  .thumbs-down.selected {
    background-color: #e54028;
  }
  .thumbs-up:hover {
    background-color: #6dc129;
  }
  .thumbs-down:hover {
    background-color: #e64f3b;
  }
  .help {
    background: url(../../assets/img/help.png) center center no-repeat #333333;
    background-image: url(../../assets/img/help.svg), none;
    left: 31px;
  }
  .help:hover {
    background-color: #3e3e3e;
  }

  /* Settings */

  .settings {
    background: url(../../assets/img/settings-61.png) right 8px no-repeat #3e3e3e;
    background-image: url(../../assets/img/settings-61.svg), none;
    height: 68px;
    width: auto;
    min-width: 60px;
    position: fixed;
    top: -8px;
    right: 16px;
  }
  .settings:hover {
    background-color: #383838;
  }
  .settings .toggle {
    width: 60px;
    height: 61px;
    margin-top: 7px;
    right: 0;
    top: 0;
    cursor: pointer;
    float: right;
  }
  .settings select {
    background-color: #323232;
    margin: 21px 20px 0;
    float: left;
    display: none;
  }

  /* Inline icons */

  span.inline {
    width: 21px;
    height: 16px;
    margin: 0 2px;
    display: inline-block;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .spannext {
    background: url(../../assets/img/next.png);
    background-image: url(../../assets/img/next.svg), none;
  }
  .spanprev {
    background: url(../../assets/img/prev.png);
    background-image: url(../../assets/img/prev.svg), none;
  }

  /* Test LPCAT Global */

  .leftside, .rightside {
    width: 68px;
    height: 620px;
    float: left;
    position: relative;
  }
  .middle {
    width: 820px;
    height: 620px;
    float: left;
    position: relative;
  }
  .leftside .help, .leftside .refresh {
    top: 6px;
    left: 0;
  }
  .rightside .next {
    bottom: 6px;
    right: 0;
  }
  .questions,
  .answers {
    border: 2px solid transparent;
    -webkit-transform: translateZ(0);
    transition: border 0.15s;
    border-radius: 22px;
  }
  .questions > div,
  .answers > div {
    width: 144px;
    height: 144px;
    padding: 6px;
    float: left;
    position: relative;
    border: 2px solid transparent;
    border-radius: 22px;
    -webkit-transform: translateZ(0);
    transition: border 0.15s;
  }
  .questions > div:before,
  .answers > div:before {
    content: '';
    position: absolute;
    width: 124px;
    height: 124px;
    top: 8px;
    left:  8px;
    background-color: #3e3e3e;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
  }
  .questions > div img,
  .answers > div img,
  .questions > div span,
  .answers > div span {
    position: absolute;
    display: inline-block;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
    transform: border 0.15s;
    cursor: pointer;
    color: white;
    font-size: 18px;
  }

  .answers img {
    cursor: pointer;
  }

  /* Test fs */

  .test-fs {
    height: 620px;
    margin-top: -9px;
  }
  .test-fs .questions {
    height: 146px;
    width: 728px;
    margin: 140px auto 15px auto;
  }
  .test-fs .questions div {
    margin-right: 1px;
  }
  .test-fs .questions div:last-child {
    margin-right: 0;
  }
  .test-fs .answers {
    height: 146px;
    width: 728px;
    margin: 15px auto 0 auto;
  }
  .test-fs .answers div {
    margin-right: 49px;
  }
  .test-fs .answers div:last-child {
    margin-right: 0;
  }
  .test-fs.demo .questions div {
    margin-right: 49px;
  }
  .test-fs.demo .questions div:last-child {
    margin-right: 0;
  }
  /* Test FA */

  .test-fa {
    height: 620px;
    margin-top: -9px;
  }
  .test-fa .questions {
    background: url(../../assets/img/dots.png) no-repeat 16px 8px;
    height: 146px;
    width: 728px;
    margin: 140px auto 15px auto;
  }
  .test-fa .questions div {
    margin-right: 31px;
  }
  .test-fa .questions div:nth-child(1) {
    margin-right: 35px;
  }
  .test-fa .questions div:nth-child(2) {
    margin-right: 82px;
  }
  .test-fa .questions div:last-child {
    margin-right: 0;
  }
  .test-fa .answers {
    height: 146px;
    width: 728px;
    margin: 15px auto 0 auto;
  }
  .test-fa .answers div {
    margin-right: 49px;
  }
  .test-fa .answers div:last-child {
    margin-right: 0;
  }

  /* Test PC */

  .test-pc {
    height: 620px;
    margin-top: -9px;
  }
  .test-pc .questions {
    height: 435px;
    width: 439px;
    margin: 0 auto 10px auto;
  }
  .test-pc .questions div {
    margin-right: 1px;
    margin-bottom: 1px;
  }
  .test-pc .answers {
    height: 146px;
    width: 728px;
    margin: 10px auto 0 auto;
  }
  .test-pc .answers div {
    margin-right: 1px;
  }
  .test-pc .answers div:last-child {
    margin-right: 0;
  }

  /* Highlights */

  .highlight {
    border: 2px solid yellow !important;
    -webkit-box-shadow: inset 0 0 5px yellow;
    -moz-box-shadow: inset 0 0 5px yellow;
    box-shadow: inset 0 0 5px yellow;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
  }
  .highlight.green {
    border: 2px solid #6dc129 !important;
    -webkit-box-shadow: inset 0 0 5px #6dc129;
    -moz-box-shadow: inset 0 0 5px #6dc129;
    box-shadow: inset 0 0 5px #6dc129;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
  }

  /* Bubbles */

  .bubble {
    position: absolute;
    background-color: #26440E;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    padding: 8px 12px;
    margin-top: -9px;
    margin-left: -8px;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
  }
  .bubble:after {
    content:"";
    position: absolute;
    width: 0;
    height: 0;
  }
  .bubble.above {
    height: 100px;
    top: 7px;
  }
  .bubble.above:after {
    top: 100%;
    border-left: 12px solid transparent;
    border-top: 12px solid #61ae24;
    border-right: 12px solid transparent;
  }
  .bubble.below {
    height: 100px;
    bottom: 7px;
  }
  .bubble.below:after {
    bottom: 100%;
    border-left: 12px solid transparent;
    border-bottom: 12px solid #61ae24;
    border-right: 12px solid transparent;
  }
  .bubble.left {
    width: 86px;
    left: 7px;
  }
  .bubble.left:after {
    left: 100%;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #61ae24;
    border-left: 12px solid rgba(97,174,36,.5);
    border-top: 12px solid transparent;
  }
  .bubble.right {
    width: 86px;
    right: 7px;
  }
  .bubble.right:after {
    right: 100%;
    border-top: 12px solid transparent;
    border-right: 12px solid #61ae24;
    border-bottom: 12px solid transparent;
  }

  /* Bubble Widths */


  .w200 { width: 200px; } .w200:after { left: 100px; }
  .w300 { width: 300px; } .w300:after { left: 150px; }
  .w400 { width: 400px; } .w400:after { left: 200px; }

  /* Bubble heights */

  .h40 { height: 40px; } .h40:after { top: 20px; }
  .h60 { height: 60px; } .h60:after { top: 30px; }
  .h80 { height: 80px; } .h80:after { top: 40px; }
  .h100 { height: 100px; } .h100:after { top: 50px; }
  .h120 { height: 120px; } .h120:after { top: 60px; }
  .h140 { height: 140px; } .h140:after { top: 70px; }
  .h160 { height: 160px; } .h160:after { top: 80px; }
  .h180 { height: 180px; } .h180:after { top: 90px; }
  .h200 { height: 200px; } .h200:after { top: 100px; }


  /* Shake */

  .wrong {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .loading .questions img, .loading .answers img {
    opacity: 0.1;
    pointer-events: none;
    border-transparent: 0
  }

  .loading .next  {
    pointer-events: none;
    background: url(../../assets/img/loader.svg) center center no-repeat #61ae24;
    background-image: url(../../assets/img/loader.svg), none;
  }

  .loading .highlight {
      border: 2px solid rgba(255, 255, 0, 0.1) !important;
  }

  /* End */
</style>
